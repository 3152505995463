import { Box, Card, CardContent, CardHeader, CircularProgress, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import rentingsModel from '../models/rentings'
import { postAPIRequest } from '../services/postAPIRequest'
import { getRentingsUnpaidUrl } from '../utils/urls'

function InvoicesNotPaidCard() {
    const { t } = useTranslation()

    const [rows, setRows] = React.useState([])

    const [isLoading, setIsLoading] = React.useState(true)

    React.useEffect(() => {
        postAPIRequest(
            getRentingsUnpaidUrl,
            { 
                "from": dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss.SSS'), 
                "to": dayjs().format('YYYY-MM-DD HH:mm:ss.SSS'), 
            },
            setRows,
            rentingsModel,
            setIsLoading
        )
    }, [])

    return (
        <Card>
            <CardHeader
                title={t('invoices_not_paid_today')}
            />
            <Divider />
            <CardContent>
                <Box
                    sx={{
                        height: 400,
                        position: 'relative',
                        overflow: "scroll",
                    }}
                >
                    <TableContainer
                        component={Paper}
                    >
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t("name")}</TableCell>
                                    <TableCell align="right">{t("amount")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((summary, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {summary.client_name}
                                        </TableCell>
                                        <TableCell align="right">{summary.price}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {isLoading === true &&
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                            <CircularProgress
                                sx={{
                                    mx: 'auto',
                                    my: 3,
                                }}
                            />
                        </Box>
                    }
                    {rows.length === 0 && isLoading === false &&
                        <Typography
                            sx={{ my: 3, }}
                            align='center'
                            color="inherit"
                            variant="subtitle1"
                            component="div"
                        >
                            {t('no_items')}
                        </Typography>
                    }
                </Box>
            </CardContent>
        </Card>
    )
}

export default InvoicesNotPaidCard