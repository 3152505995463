import * as React from 'react'
import PropTypes from 'prop-types'
import { alpha } from '@mui/material/styles'
import { FileDownloadOutlined, FilterList, } from "@mui/icons-material"
import { IconButton, MenuItem, MenuList, Popover, TextField, Toolbar, Tooltip, Typography } from "@mui/material"
import uuid from 'react-uuid'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

function EnhancedTableToolbar({
  handleSearch,
  handleExport,
  filterBy,
  handleClickFilterBy,
  label,
  setBody,
}) {
  const { t } = useTranslation()

  const [action, setAction] = React.useState("")

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event, value) => {
    setAction(value)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAction("")
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const filter = [
    {
      filterName: "today",
      body: {
        "from": dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss.SSS'),
        "to": dayjs().format('YYYY-MM-DD HH:mm:ss.SSS'),
      },
    },
    {
      filterName: "yesterday",
      body: {
        "from": dayjs().subtract(1, 'day').startOf('day').format('YYYY-MM-DD HH:mm:ss.SSS'),
        "to": dayjs().subtract(1, 'day').endOf('day').format('YYYY-MM-DD HH:mm:ss.SSS'),
      },
    },
    {
      filterName: "last_7_days",
      body: {
        "from": dayjs().subtract(6, 'day').startOf('day').format('YYYY-MM-DD HH:mm:ss.SSS'),
        "to": dayjs().format('YYYY-MM-DD HH:mm:ss.SSS'),
      },
    },
    {
      filterName: "all_time",
      body: {
        "from": dayjs().startOf('year').format('YYYY-MM-DD HH:mm:ss.SSS'),
        "to": dayjs().format('YYYY-MM-DD HH:mm:ss.SSS'),
      },
    },
  ]

  const download = [
    {
      downloadName: "Excel",
      fileType: "excel",
    },
    {
      downloadName: "PDF",
      fileType: "pdf",
    },
  ]

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        bgcolor: (theme) =>
          alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
      }}
    >
      <Typography
        sx={{ flex: '1 1 100%' }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {t(label)}
      </Typography>

      <TextField
        id="search"
        label={t('search')}
        variant="outlined"
        size='small'
        color="primary"
        onChange={handleSearch}
      />

      <Tooltip title={t('download_excel')}>
        <IconButton
          onClick={(event) => {
            handleClick(event, "download")
          }}
        >
          <FileDownloadOutlined />
        </IconButton>
      </Tooltip>

      <Tooltip title={t('filter_list')}>
        <IconButton
          onClick={(event) => {
            handleClick(event, "filter")
          }}
        >
          <FilterList />
        </IconButton>
      </Tooltip>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: { width: '150px' }
        }}
      >
        <MenuList
          disablePadding
          sx={{
            '& > *': {
              '&:first-of-type': {
                borderTopColor: 'divider',
                borderTopStyle: 'solid',
                borderTopWidth: '1px'
              },
              padding: '12px 16px'
            }
          }}
        >
          {action === "filter" && filter.map((item, index) => (
            <MenuItem
              key={uuid()}
              sx={{
                backgroundColor: filterBy === index && 'rgba(254, 0, 2, 0.3)',
              }}
              onClick={(event) => {
                handleClickFilterBy(event, index)
                setBody(item.body)
                handleClose()
              }}
            >
              {t(item.filterName)}
            </MenuItem>
          ))}
          {action === "download" && download.map((item, index) => (
            <MenuItem
              key={uuid()}
              sx={{
                backgroundColor: filterBy === index && 'rgba(254, 0, 2, 0.3)',
              }}
              onClick={(event) => {
                handleExport(item.fileType)
                handleClose()
              }}
            >
              {item.downloadName}
            </MenuItem>
          ))}
        </MenuList>
      </Popover>
    </Toolbar>
  )
}

export default EnhancedTableToolbar

EnhancedTableToolbar.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  handleExport: PropTypes.func.isRequired,
}