import * as React from 'react'
import { DataGrid, DeleteDialog, ProfileDialog, } from '../components'
import { useTranslation } from 'react-i18next'
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { driverHeader } from '../utils/tableHeaders'
import { addDriverUrl, deleteDriverUrl, getAllDriverUrl, updateDriverStatusUrl, updateDriverUrl } from '../utils/urls'
import { Button, Grid, Snackbar } from '@mui/material'
import MuiAlert from '@mui/material/Alert'
import { Box } from '@mui/system'
import { DeleteOutline, DriveEtaOutlined, EditOutlined, RemoveRedEyeOutlined } from '@mui/icons-material'
import { postAPIRequest } from '../services/postAPIRequest'
import dayjs from 'dayjs'
import { driverRegistrationFields, updateDriverRegistrationFields } from '../utils/formFields'
import driversModel from '../models/drivers'
import { AuthContext } from '../contexts/AuthContext';
import axios from 'axios';

pdfMake.vfs = pdfFonts.pdfMake.vfs
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

function Drivers() {
    const { t } = useTranslation()
    const { currentUser, } = React.useContext(AuthContext)
    const [rows, setRows] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(true)
    const [action, setAction] = React.useState("")
    const [selected, setSelected] = React.useState([])
    const [openProfileDialog, setOpenProfileDialog] = React.useState(false)
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false)
    const [searchTerm, setSearchTerm] = React.useState("")
    const [isSubmitting, setIsSubmitting] = React.useState(false)
    const [openAlert, setOpenAlert] = React.useState(false)
    const [severity, setSeverity] = React.useState("")
    const [severityMessage, setSeverityMessage] = React.useState("")
    const [history, setHistory] = React.useState([])
    const [body, setBody] = React.useState({
        "from": dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss.SSS'),
        "to": dayjs().add(5, "minute").format('YYYY-MM-DD HH:mm:ss.SSS'),
    })

    React.useEffect(() => {
        postAPIRequest(getAllDriverUrl, body, setRows, driversModel, setIsLoading)
    }, [body])

    const values = [
        {
            full_name: action === "view" || action === "edit" ? selected[0]?.full_name : "",
            experience: action === "view" || action === "edit" ? selected[0]?.experience : "",
            phone_number: action === "view" || action === "edit" ? selected[0]?.phone_number : "",
            price: action === "view" || action === "edit" ? selected[0]?.price : "",
        },
    ]

    const updateValues = [
        {
            full_name: action === "view" || action === "edit" ? selected[0]?.full_name : "",
            experience: action === "view" || action === "edit" ? selected[0]?.experience : "",
            phone_number: action === "view" || action === "edit" ? selected[0]?.phone_number : "",
            price: action === "view" || action === "edit" ? selected[0]?.price : "",
        },
    ]

    const handleSearch = event => {
        setSearchTerm(event.target.value)
    }

    const handleClickOpenProfileDialog = () => {
        setOpenProfileDialog(true)
    }

    const handleCloseProfileDialog = () => {
        setOpenProfileDialog(false)
    }

    const handleClickOpenDeleteDialog = () => {
        setOpenDeleteDialog(true)
    }

    const handleCloseDeleteDialog = () => {
        handleRefresh()
        setOpenDeleteDialog(false)
    }

    const handleRefresh = () => {
        postAPIRequest(getAllDriverUrl, body, setRows, driversModel, setIsLoading)
    }

    const handleSwitchChange = async (row, index) => {
        setIsSubmitting(true)
        try {
            const response = await axios.post(
                updateDriverStatusUrl,
                { "driver_id": row.id, "user_id": currentUser?.id },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'car-renting-sign-auth': `${process.env.REACT_APP_KEY}`,
                        'car-renting-token-auth': currentUser?.token,
                    },
                }
            )
            if (response.data.error === false) {
                handleRefresh()
                setIsSubmitting(false)
            }
        } catch (error) {
            setIsSubmitting(false)
        }
    }

    const handleClickAlert = () => {
        setOpenAlert(true)
    }

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setOpenAlert(false)
    }

    const handleUndo = async () => {
        setIsSubmitting(true)
        try {
            const response = await axios.post(
                deleteDriverUrl,
                { "driver_id": history[0]?.id, "user_id": currentUser?.id },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'car-renting-sign-auth': `${process.env.REACT_APP_KEY}`,
                        'car-renting-token-auth': currentUser?.token,
                    },
                }
            )
            if (response.data.error === false) {
                handleRefresh()
                setIsSubmitting(false)
            }
        } catch (error) {
            setSeverityMessage(error.response.data.message[0])
            setSeverity("error")
            handleClickAlert()
            setIsSubmitting(false)
        }
    }

    const driverRegistrationPopoverItems = [
        {
            id: 'viewDriver',
            label: 'view',
            icon: <RemoveRedEyeOutlined fontSize="small" />,
            onClick: () => {
                setAction("view")
                handleClickOpenProfileDialog()
            },
        },
        {
            id: 'editDriver',
            label: 'edit',
            icon: <EditOutlined fontSize="small" />,
            onClick: () => {
                setAction("edit")
                handleClickOpenProfileDialog()
            },
        },
        {
            id: 'deleteDriver',
            label: 'delete',
            icon: <DeleteOutline fontSize="small" />,
            onClick: () => {
                setAction("delete")
                handleClickOpenDeleteDialog()
            },
        },
    ]

    return (
        <>
            {action === "edit" &&
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={openAlert}
                    autoHideDuration={6000}
                    onClose={handleCloseAlert}
                >
                    <Alert
                        onClose={handleCloseAlert}
                        severity={severity}
                        sx={{
                            width: '100%'
                        }}
                    >
                        {severityMessage}
                    </Alert>
                </Snackbar>
            }
            {action === "delete" &&
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={openAlert}
                    autoHideDuration={6000}
                    message={severityMessage}
                    onClose={handleCloseAlert}
                    action={
                        <Button
                            color="inherit"
                            size="small"
                            onClick={() => {
                                handleUndo()
                                handleCloseAlert()
                            }}
                        >
                            Undo
                        </Button>
                    }
                />
            }
            <Grid container>
                <Grid item xs={6}>
                    <Box
                        sx={{
                            display: 'flex',
                            ml: 3,
                            pt: 4,
                        }}
                    >
                        <Button
                            startIcon={<DriveEtaOutlined />}
                            variant="contained"
                            size="small"
                            sx={{
                                '&:hover': {
                                    backgroundColor: "primary.main",
                                },
                            }}
                            onClick={() => {
                                setAction("create")
                                handleClickOpenProfileDialog()
                            }}
                        >
                            {t('register_driver')}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <ProfileDialog
                openProfileDialog={openProfileDialog}
                handleCloseProfileDialog={handleCloseProfileDialog}
                from="driver"
                values={action === "create" ? values : updateValues}
                fields={action === "create" ? driverRegistrationFields : updateDriverRegistrationFields}
                url={action === "create" ? addDriverUrl : updateDriverUrl}
                handleRefresh={handleRefresh}
                action={action}
                title={action === "create" ? "register_driver" : "update_driver"}
                submitButtonText={action === "create" ? "create" : "update"}
                selected={selected}
                setSeverity={setSeverity}
                setSeverityMessage={setSeverityMessage}
                handleClickAlert={handleClickAlert}
            />
            <DeleteDialog
                openDeleteDialog={openDeleteDialog}
                handleCloseDeleteDialog={handleCloseDeleteDialog}
                url={deleteDriverUrl}
                body={selected.length === 1 && { "driver_id": selected[0]?.id, "user_id": currentUser?.id }}
                selected={selected}
                setSeverity={setSeverity}
                setSeverityMessage={setSeverityMessage}
                handleClickAlert={handleClickAlert}
                history={history}
                setHistory={setHistory}
            />
            <DataGrid
                t={t}
                rows={rows}
                isLoading={isLoading}
                popoverItems={driverRegistrationPopoverItems}
                headCells={driverHeader}
                setSelected={setSelected}
                label="registered_drivers"
                searchTerm={searchTerm}
                handleSearch={handleSearch}
                from="drivers"
                setBody={setBody}
                handleSwitchChange={handleSwitchChange}
                isSubmitting={isSubmitting}
            />
        </>
    )
}

export default Drivers