import React from 'react'
import { Line } from 'react-chartjs-2'
import { Box, Button, Card, CardContent, CardHeader, Divider, useTheme } from '@mui/material'
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
} from 'chart.js'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { getRentingsUrl } from '../utils/urls'
import dayjs from 'dayjs'
import { AuthContext } from '../contexts/AuthContext'

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip
)

function GraphSummary() {
    const { t } = useTranslation()
    const { currentUser, } = React.useContext(AuthContext)

    const [data, setData] = React.useState([])
    React.useEffect(() => {
        const fetchData = async () => {
            const response = await axios.post(
                getRentingsUrl,
                {
                    "from": dayjs().subtract(6, 'day').startOf('day').format('YYYY-MM-DD HH:mm:ss.SSS'),
                    "to": dayjs().format('YYYY-MM-DD HH:mm:ss.SSS'),
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'car-renting-sign-auth': `${process.env.REACT_APP_KEY}`,
                        'car-renting-token-auth': currentUser?.token,
                    },
                }
            )
            if (response.data.error === false) {
                const json = await response.data.data

                // Group the data by date using an object
                const groupedData = json.reduce((acc, obj) => {
                    const date = obj.created_at.split(', ')[0]
                    acc[date] = acc[date] || []
                    acc[date].push(obj.price)
                    return acc
                }, {})

                // Convert the grouped data into an array of objects with 'date' and 'value' properties
                const dataArray = Object.keys(groupedData).map(date => {
                    const sum = groupedData[date].reduce((total, val) => total + val, 0)
                    // const avg = sum / groupedData[date].length
                    return { date, value: sum }
                })
                setData(dataArray)
            }
        }

        fetchData()
    }, [currentUser])

    const theme = useTheme()

    const options = {
        animation: false,
        cornerRadius: 20,
        layout: { padding: 0 },
        legend: { display: false },
        maintainAspectRatio: false,
        responsive: true,
        xAxes: [
            {
                ticks: {
                    fontColor: theme.palette.text.secondary
                },
                gridLines: {
                    display: false,
                    drawBorder: false
                }
            }
        ],
        yAxes: [
            {
                ticks: {
                    fontColor: theme.palette.text.secondary,
                    beginAtZero: true,
                    min: 0
                },
                gridLines: {
                    borderDash: [2],
                    borderDashOffset: [2],
                    color: theme.palette.divider,
                    drawBorder: false,
                    zeroLineBorderDash: [2],
                    zeroLineBorderDashOffset: [2],
                    zeroLineColor: theme.palette.divider
                }
            }
        ],
        tooltips: {
            backgroundColor: theme.palette.background.paper,
            bodyFontColor: theme.palette.text.secondary,
            borderColor: theme.palette.divider,
            borderWidth: 1,
            enabled: true,
            footerFontColor: theme.palette.text.secondary,
            intersect: false,
            mode: 'index',
            titleFontColor: theme.palette.text.primary
        }
    }

    return (
        <Card>
            <CardHeader
                action={(
                    <Button
                        size="small"
                    >
                        {t('last_7_days')}
                    </Button>
                )}
                title={t('car_renting')}
            />
            <Divider />
            <CardContent>
                <Box
                    sx={{
                        height: 400,
                        position: 'relative'
                    }}
                >
                    <Line
                        data={{
                            datasets: [
                                {
                                    backgroundColor: '#FE0002',
                                    barPercentage: 0.5,
                                    barThickness: 12,
                                    borderRadius: 4,
                                    categoryPercentage: 0.5,
                                    data: data.map((d) => d.value),
                                    label: t('summary'),
                                    maxBarThickness: 10
                                },
                            ],
                            labels: data.map((d) => t(d.date)),
                        }}
                        options={options}
                    />
                </Box>
            </CardContent>
        </Card>
    )
}

export default GraphSummary