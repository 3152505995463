import React from 'react'
import { Close, PrintOutlined, } from '@mui/icons-material'
import { AppBar, Box, Button, Container, Dialog, DialogContent, IconButton, Toolbar, } from '@mui/material'
import TabPanel from './TabPanel'
import { useTranslation } from 'react-i18next'
import User from './User'

function ViewDialog({
    openViewDialog,
    handleCloseViewDialog,
    rows,
    action,
    selected,
    setSelected,
    handleRefresh,
}) {
    const { t } = useTranslation()
    const [currentTab, setCurrentTab] = React.useState(0)

    const handlePrint = () => {
        window.print()
    }

    return (
        <Dialog
            open={openViewDialog}
            onClose={handleCloseViewDialog}
            fullScreen
            PaperProps={{
                style: {
                    boxShadow: "none"
                },
            }}
        >
            <DialogContent
                color='background'
            >
                <AppBar
                    id='appbar'
                    sx={{
                        position: 'relative',
                    }}
                    color='background'
                    elevation={0}
                >
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => {
                                handleCloseViewDialog()
                                setCurrentTab(0)
                            }}
                            aria-label="close"
                        >
                            <Close />
                        </IconButton>
                        <Box sx={{ flexGrow: 1 }} />
                        {(action === "invoice" && currentTab === 0) || (action === "invoice" && currentTab === 1 && selected[0]?.payments.length !== 0) ?
                            <Button
                                autoFocus
                                color="primary"
                                startIcon={<PrintOutlined />}
                                variant="contained"
                                size="small"
                                sx={{
                                    '&:hover': {
                                        backgroundColor: "primary.main",
                                    },
                                }}
                                onClick={() => {
                                    currentTab === 0 ?
                                        handlePrint() :
                                        handlePrint()
                                }}
                            >
                                {
                                    currentTab === 0 ?
                                        t("print_invoice") :
                                        t("print_receipt")
                                }
                            </Button> : null}
                    </Toolbar>
                </AppBar>
                <Container
                    maxWidth="md"
                >
                    {action === "invoice" &&
                        <TabPanel
                            rows={rows}
                            setCurrentTab={setCurrentTab}
                            action="create"
                            selected={selected}
                            setSelected={setSelected}
                            handleRefresh={handleRefresh}
                        />
                    }
                    {action === "user" &&
                        <User
                            selected={selected}
                        />
                    }
                </Container>
            </DialogContent>
        </Dialog>
    )
}

export default ViewDialog