import React from 'react'
import { Box, Button, Pagination, Typography } from '@mui/material'
import printJS from 'print-js'
import { useTranslation } from 'react-i18next'

function Receipt({
    rows,
    selected,
}) {
    const { t } = useTranslation()
    const totalPrice = rows[selected[0]?.index]?.car_price + rows[selected[0]?.index]?.driver_price
    const [page, setPage] = React.useState(1)
    const handleChange = (event, value) => {
        setPage(value)
    }
    const receiptContent = `----------------------------------
    ${t("receipt")}
    ----------------------------------
    ${t("car_price")}        TZS ${rows[selected[0]?.index]?.car_price}
    ${t("driver_price")}     TZS ${rows[selected[0]?.index]?.driver_price}
    ----------------------------------
    ${t("total")}:           TZS ${totalPrice}
    ----------------------------------
    Thank you for shopping!
    `

    const printReceipt = () => {
        printJS({
            printable: receiptContent,
            type: 'raw-html',
            documentTitle: 'Receipt'
        });
    }

    return (
        <>
            {rows[selected[0]?.index]?.payments.length === 0 &&
                <Box>
                    <Typography
                        variant='h4'
                        textAlign="center"
                    >
                        Invoice not paid
                    </Typography>
                    <Button title="Print" onClick={printReceipt} ></Button>
                </Box>
            }
            {rows[selected[0]?.index]?.payments.length !== 0 &&
                <Box>
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                        }}
                    >
                        <Box
                            component='img'
                            sx={{
                                width: 0.10,
                                mx: 'auto',
                            }}
                            alt="Logo"
                            src={require("../assets/images/logo.png")}
                        />
                    </Box>
                    <Typography
                        variant='h4'
                        textAlign="center"
                    >
                        ----------------------------------<br />
                        {t("receipt")}<br />
                        ----------------------------------<br />
                    </Typography>
                    {rows[selected[0]?.index]?.cars.map((driver, index) => (
                        <Typography
                            variant='h4'
                            textAlign="center"
                        >
                            {`${t("item")} ${index + 1}`} :
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {rows[selected[0]?.index]?.cars[index].car_model}({rows[selected[0]?.index]?.cars[index].car_type})<br />
                        </Typography>
                    ))}
                    <Typography
                        variant='h4'
                        textAlign="center"
                    >
                        ----------------------------------<br />
                        {t("total")} :
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        TZS {rows[selected[0]?.index]?.payments[page - 1].amount}<br />
                        ----------------------------------<br />
                        {t("thank_you_for_renting")}<br />
                    </Typography>
                    <Box
                        id="pagination"
                        sx={{
                            display: "flex",
                            justifyContent: "end",
                            mt: 4,
                        }}
                    >
                        <Pagination
                            color='primary'
                            variant='outlined'
                            count={rows[selected[0]?.index]?.payments.length}
                            page={page}
                            onChange={handleChange}
                        />
                    </Box>
                </Box>
            }
        </>
    )
}

export default Receipt