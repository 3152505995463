export const userHeader = [
    {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'S/NO.',
    },
    {
        id: 'full_name',
        numeric: false,
        disablePadding: false,
        label: 'full_name',
    },
    {
        id: 'gender',
        numeric: false,
        disablePadding: false,
        label: 'gender',
    },
    {
        id: 'phone_no',
        numeric: false,
        disablePadding: false,
        label: 'phone_number',
    },
    {
        id: 'email',
        numeric: false,
        disablePadding: false,
        label: 'email',
    },
    {
        id: 'role',
        numeric: false,
        disablePadding: false,
        label: 'role',
    },
    {
        id: 'updated_at',
        numeric: false,
        disablePadding: false,
        label: 'modified_at',
    },
]

export const carRegistrationHeader = [
    {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'S/NO.',
    },
    {
        id: 'car_model',
        numeric: false,
        disablePadding: false,
        label: 'car_model',
    },
    {
        id: 'chassis_no',
        numeric: false,
        disablePadding: false,
        label: 'chassis_no',
    },
    {
        id: 'car_type',
        numeric: false,
        disablePadding: false,
        label: 'car_type',
    },
    {
        id: 'price',
        numeric: false,
        disablePadding: false,
        label: 'price',
    },
    {
        id: 'year',
        numeric: false,
        disablePadding: false,
        label: 'year',
    },
    {
        id: 'color',
        numeric: false,
        disablePadding: false,
        label: 'color',
    },
    {
        id: 'plate_number',
        numeric: false,
        disablePadding: false,
        label: 'plate_number',
    },
    {
        id: 'fuel',
        numeric: false,
        disablePadding: false,
        label: 'fuel',
    },
    {
        id: 'transmission',
        numeric: false,
        disablePadding: false,
        label: 'transmission',
    },
    {
        id: 'engine_size',
        numeric: false,
        disablePadding: false,
        label: 'engine_size',
    },
    {
        id: 'wheel_drive',
        numeric: false,
        disablePadding: false,
        label: 'wheel_drive',
    },
    {
        id: 'vehicle_descriptions',
        numeric: false,
        disablePadding: false,
        label: 'vehicle_description',
    },
    {
        id: 'updated_at',
        numeric: false,
        disablePadding: false,
        label: 'modified_at',
    },
]

export const carRentingHeader = [
    {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'S/NO.',
    },
    {
        id: 'client_name',
        numeric: false,
        disablePadding: false,
        label: 'client_full_name',
    },
    {
        id: 'phone_number',
        numeric: false,
        disablePadding: false,
        label: 'phone_number',
    },
    {
        id: 'email',
        numeric: false,
        disablePadding: false,
        label: 'email',
    },
    {
        id: 'organization_tin',
        numeric: false,
        disablePadding: false,
        label: 'organization_tin',
    },
    {
        id: 'driver',
        numeric: false,
        disablePadding: false,
        label: 'driver',
    },
    {
        id: 'car_price',
        numeric: false,
        disablePadding: false,
        label: 'car_price',
    },
    {
        id: 'driver_price',
        numeric: false,
        disablePadding: false,
        label: 'driver_price',
    },
    {
        id: 'paid_amount',
        numeric: false,
        disablePadding: false,
        label: 'paid_amount',
    },
    {
        id: 'cars',
        numeric: false,
        disablePadding: false,
        label: 'car_information',
    },
    {
        id: 'drivers',
        numeric: false,
        disablePadding: false,
        label: 'driver_information',
    },
    {
        id: 'created_at',
        numeric: false,
        disablePadding: false,
        label: 'modified_at',
    },
]

export const driverHeader = [
    {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'S/NO.',
    },
    {
        id: 'full_name',
        numeric: false,
        disablePadding: false,
        label: 'full_name',
    },
    {
        id: 'date_of_birth',
        numeric: false,
        disablePadding: false,
        label: 'date_of_birth',
    },
    {
        id: 'gender',
        numeric: false,
        disablePadding: false,
        label: 'gender',
    },
    {
        id: 'experience',
        numeric: false,
        disablePadding: false,
        label: 'experience',
    },
    {
        id: 'phone_number',
        numeric: false,
        disablePadding: false,
        label: 'phone_number',
    },
    {
        id: 'price',
        numeric: false,
        disablePadding: false,
        label: 'price',
    },
]