import React from 'react'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import * as Constants from '../utils/constants'
import NavigationBar from './NavigationBar'
import SideBar from './SideBar'
import { useTranslation } from 'react-i18next'

function Layout({ children, }) {
    const { t } = useTranslation()
    const LayoutRoot = styled('div')(({ theme }) => ({
        display: 'flex',
        flex: '1 1 auto',
        maxWidth: '100%',
        paddingTop: 64,
        [theme.breakpoints.up('lg')]: {
            paddingLeft: 280
        }
    }))
    const [isSidebarOpen, setSidebarOpen] = React.useState(true)

    return (
        <>
            <LayoutRoot>
                <Box
                    sx={{
                        display: 'flex',
                        flex: '1 1 auto',
                        flexDirection: 'column',
                        width: '100%'
                    }}
                >
                    {children}
                    <Box
                        sx={{
                            height: 60,
                        }}
                    />
                </Box>
                <Box
                    id="footer"
                    sx={{
                        position: "fixed",
                        bottom: 0,
                        right: 0,
                        left: 0,
                        height: 60,
                        width: "100%",
                        backgroundColor: "background.paper",
                        display: 'flex',
                        alignItems: "center",
                        alignContent: "center",
                        justifyContent: "flex-end",
                    }}
                >
                    <Typography>
                        {t("copyright")} © {new Date().getFullYear()} {Constants.APP_NAME}.
                    </Typography>
                </Box>
            </LayoutRoot>
            <NavigationBar
                onSidebarOpen={() => setSidebarOpen(true)}
            />
            <SideBar
                onClose={() => setSidebarOpen(false)}
                open={isSidebarOpen}
            />
        </>
    )
}

export default Layout