class Drivers {
    constructor({
        id,
        full_name,
        date_of_birth,
        gender,
        experience,
        phone_number,
        price,
        active,
        created_at,
        updated_at,
    }) {
        this.id = id
        this.full_name = full_name
        this.date_of_birth = date_of_birth
        this.gender = gender
        this.experience = experience
        this.phone_number = phone_number
        this.price = price
        this.active = active
        this.created_at = created_at
        this.updated_at = updated_at
    }
}

function driversModel(apiResponse) {
    const {
        id,
        full_name,
        date_of_birth,
        gender,
        experience,
        phone_number,
        price,
        active,
        created_at,
        updated_at,
    } = apiResponse
    return new Drivers({
        id,
        full_name,
        date_of_birth,
        gender,
        experience,
        phone_number,
        price,
        active,
        created_at,
        updated_at,
    })
}

export default driversModel