import * as React from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { AppBar, Box, IconButton, MenuItem, MenuList, Popover, Snackbar, styled, Toolbar, Tooltip, } from '@mui/material'
import MuiAlert from '@mui/material/Alert'
import { ArrowDropDown, LanguageOutlined, Menu, } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import ProfileDialog from './ProfileDialog'
import { usePopover } from '../hooks/usePopover'
import { changePasswordFields } from '../utils/formFields'
import { changePasswordUrl } from '../utils/urls'

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
}))
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const values = [
    {
        old_password: "",
        new_password: "",
        confirm_new_password: "",
    },
]

function NavigationBar({ onSidebarOpen, }) {
    const { t } = useTranslation()
    const { i18n } = useTranslation()
    const { currentUser, setCurrentUser } = React.useContext(AuthContext)
    const [action, setAction] = React.useState("")
    const [openProfileDialog, setOpenProfileDialog] = React.useState(false)
    const [openAlert, setOpenAlert] = React.useState(false)
    const [severity, setSeverity] = React.useState("")
    const [severityMessage, setSeverityMessage] = React.useState("")

    const handleClickOpenProfileDialog = () => {
        setOpenProfileDialog(true)
    }

    const handleCloseProfileDialog = () => {
        setOpenProfileDialog(false)
    }

    const handleLanguageChange = (lng) => {
        i18n.changeLanguage(lng)
        accountPopover.handleClose()
    }

    const accountPopover = usePopover()

    const handleSignOut = async () => {
        localStorage.removeItem("carRentingWebUser")
        setCurrentUser(null)
    }

    const handleClickAlert = () => {
        setOpenAlert(true)
    }

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setOpenAlert(false)
    }

    return (
        <DashboardNavbarRoot
            sx={{
                left: {
                    lg: 280
                },
                width: {
                    lg: 'calc(100% - 280px)'
                }
            }}
        >
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={openAlert}
                autoHideDuration={6000}
                onClose={handleCloseAlert}
            >
                <Alert
                    onClose={handleCloseAlert}
                    severity={severity}
                    sx={{
                        width: '100%'
                    }}
                >
                    {severityMessage}
                </Alert>
            </Snackbar>
            <Toolbar
                disableGutters
                sx={{
                    minHeight: 64,
                    left: 0,
                    px: 2
                }}
            >
                <IconButton
                    onClick={onSidebarOpen}
                    sx={{
                        display: {
                            xs: 'inline-flex',
                            lg: 'none'
                        }
                    }}
                >
                    <Menu fontSize="small" />
                </IconButton>
                <Box sx={{ flexGrow: 1 }} />
                <Tooltip title={t('change_language')}>
                    <IconButton
                        onClick={() => {
                            setAction("language")
                            accountPopover.handleOpen()
                        }}
                        ref={accountPopover.anchorRef}
                        sx={{ ml: 1 }}
                    >
                        <LanguageOutlined />
                    </IconButton>
                </Tooltip>
                <IconButton
                    onClick={() => {
                        setAction("profile")
                        accountPopover.handleOpen()
                    }}
                    ref={accountPopover.anchorRef}
                    sx={{
                        cursor: 'pointer',
                        ml: 1,
                        '&:hover': {
                            backgroundColor: "background.paper",
                        },
                    }}
                >
                    {currentUser?.full_name}
                    <ArrowDropDown />
                </IconButton>
            </Toolbar>
            <Popover
                open={accountPopover.open}
                anchorEl={accountPopover.anchorRef.current}
                onClose={accountPopover.handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                PaperProps={{
                    sx: { width: '300px' }
                }}
            >
                <MenuList
                    disablePadding
                    sx={{
                        '& > *': {
                            '&:first-of-type': {
                                borderTopColor: 'divider',
                                borderTopStyle: 'solid',
                                borderTopWidth: '1px'
                            },
                            padding: '12px 16px'
                        }
                    }}
                >
                    <MenuItem
                        sx={{
                            backgroundColor: i18n.language === "en" &&
                                action === "language" && 'rgba(254, 0, 2, 0.3)',
                        }}
                        onClick={() => {
                            action === "language" && handleLanguageChange('en')
                            action === "profile" && handleClickOpenProfileDialog()
                            accountPopover.handleClose()
                        }}
                    >
                        {action === "language" ? "English" : t('change_password')}
                    </MenuItem>
                    <MenuItem
                        sx={{
                            backgroundColor: i18n.language === "sw" &&
                                action === "language" && 'rgba(254, 0, 2, 0.3)',
                        }}
                        onClick={() => {
                            action === "language" && handleLanguageChange('sw')
                            action === "profile" && handleSignOut()
                            accountPopover.handleClose()
                        }}
                    >
                        {action === "language" ? "Swahili" : t('logout')}
                    </MenuItem>
                </MenuList>
            </Popover>
            <ProfileDialog
                openProfileDialog={openProfileDialog}
                handleCloseProfileDialog={handleCloseProfileDialog}
                from={action}
                values={values}
                fields={changePasswordFields}
                url={changePasswordUrl}
                action="create"
                title="change_password"
                submitButtonText="update"
                setSeverity={setSeverity}
                setSeverityMessage={setSeverityMessage}
                handleClickAlert={handleClickAlert}
            />
        </DashboardNavbarRoot>
    )
}

export default NavigationBar