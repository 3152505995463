import * as React from 'react'
import { AuthContext } from "./contexts/AuthContext"
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { Bookings, Cars, Drivers, Home, Invoices, Login, Orders, Reports, Users } from "./pages"
import { Layout } from './components'
import theme from './theme'
import uuid from 'react-uuid'

function App() {
  const { currentUser, } = React.useContext(AuthContext)

  const ProtectedRoute = ({ children }) => {
    if (!currentUser) {
      return <Navigate to="/login" replace={true} />
    }

    return children
  }

  const items = [
    {
      path: '/login',
      component: <Login />,
    },
    {
      path: '/',
      component:
        <ProtectedRoute>
          <Layout>
            <Home />
          </Layout>
        </ProtectedRoute>,
    },
    {
      path: 'cars',
      component:
        <ProtectedRoute>
          <Layout>
            <Cars />
          </Layout>
        </ProtectedRoute>,
    },
    {
      path: 'drivers',
      component:
        <ProtectedRoute>
          <Layout>
            <Drivers />
          </Layout>
        </ProtectedRoute>,
    },
    {
      path: 'orders',
      component:
        <ProtectedRoute>
          <Layout>
            <Orders />
          </Layout>
        </ProtectedRoute>,
    },
    {
      path: 'invoices',
      component:
        <ProtectedRoute>
          <Layout>
            <Invoices />
          </Layout>
        </ProtectedRoute>,
    },
    {
      path: 'bookings',
      component:
        <ProtectedRoute>
          <Layout>
            <Bookings />
          </Layout>
        </ProtectedRoute>,
    },
    {
      path: 'users',
      component:
        <ProtectedRoute>
          <Layout>
            <Users />
          </Layout>
        </ProtectedRoute>,
    },
    {
      path: 'reports',
      component:
        <ProtectedRoute>
          <Layout>
            <Reports />
          </Layout>
        </ProtectedRoute>,
    },
  ]

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path="/">
            {
              items.map(
                (item, routeIndex) => (
                  routeIndex === 1 ?
                    <Route
                      key={uuid()}
                      index
                      element={item.component}
                    /> : <Route
                      key={uuid()}
                      path={item.path}
                      element={item.component}
                    />
                )
              )
            }
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App