class Users {
    constructor({
        id,
        full_name,
        email,
        phone_no,
        gender,
        role,
        active,
        created_at,
        updated_at,
    }) {
        this.id = id
        this.full_name = full_name
        this.email = email
        this.phone_no = phone_no
        this.gender = gender
        this.role = role
        this.active = active
        this.created_at = created_at
        this.updated_at = updated_at
    }
}

function usersModel(apiResponse) {
    const {
        id,
        full_name,
        email,
        phone_no,
        gender,
        role,
        active,
        created_at,
        updated_at,
    } = apiResponse
    return new Users({
        id,
        full_name,
        email,
        phone_no,
        gender,
        role,
        active,
        created_at,
        updated_at,
    })
}

export default usersModel