class Retings {
    constructor({
        id,
        client_name,
        phone_number,
        email,
        organization_tin,
        driver,
        car_id,
        price,
        car_model,
        chassis_no,
        car_type,
        year,
        color,
        plate_number,
        fuel,
        transmission,
        engine_size,
        wheel_drive,
        vehicle_descriptions,
        status,
        created_at,
        updated_at,
    }) {
        this.id = id
        this.client_name = client_name
        this.phone_number = phone_number
        this.email = email
        this.organization_tin = organization_tin
        this.driver = driver
        this.car_id = car_id
        this.price = price
        this.car_model = car_model
        this.chassis_no = chassis_no
        this.car_type = car_type
        this.year = year
        this.color = color
        this.plate_number = plate_number
        this.fuel = fuel
        this.transmission = transmission
        this.engine_size = engine_size
        this.wheel_drive = wheel_drive
        this.vehicle_descriptions = vehicle_descriptions
        this.status = status
        this.created_at = created_at
        this.updated_at = updated_at
    }
}

function rentingsModel(apiResponse) {
    const {
        id,
        client_name,
        phone_number,
        email,
        organization_tin,
        driver,
        car_id,
        price,
        car_model,
        chassis_no,
        car_type,
        year,
        color,
        plate_number,
        fuel,
        transmission,
        engine_size,
        wheel_drive,
        vehicle_descriptions,
        status,
        created_at,
        updated_at,
    } = apiResponse
    return new Retings({
        id,
        client_name,
        phone_number,
        email,
        organization_tin,
        driver,
        car_id,
        price,
        car_model,
        chassis_no,
        car_type,
        year,
        color,
        plate_number,
        fuel,
        transmission,
        engine_size,
        wheel_drive,
        vehicle_descriptions,
        status,
        created_at,
        updated_at,
    })
}

export default rentingsModel