import React from 'react'
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import dayjs from 'dayjs'

const items = [
    {
        id: 1,
        description: "CIF(COST, INSUARENCE AND FREIGHT) and TRANSPORTATION CHARGE(TO JAPAN)",
        descriptionLines: [],
        quantity: 2,
        usd: "5,459",
        tzs: "12,861,104/-",
        roe: 2354,
        total: "12,861,104/-",
    },
    {
        id: 2,
        description: "TANZANIA REVENUE AUTHORITY(TRA",
        descriptionLines: [],
        quantity: 1,
        usd: "",
        tzs: "8,861,104/-",
        roe: "",
        total: "12,861,104/-",
    },
    {
        id: 3,
        description: "TANZANIA PORT AUTHORITY(TPA -Corridor levy -Wharfage -D.o -Tbs/ inspection",
        descriptionLines: [],
        quantity: 4,
        usd: "",
        tzs: "8,861,104/-",
        roe: "",
        total: "12,861,104/-",
    },
    {
        id: 4,
        description: "CLEARANCE + PLATE NUMBER",
        descriptionLines: [],
        quantity: 1,
        usd: "",
        tzs: "350,000",
        roe: "",
        total: "350,000",
    },
    {
        id: 5,
        description: "TRANSPORTATION -FUEL -DRIVER",
        descriptionLines: [],
        quantity: 2,
        usd: "",
        tzs: "NIL",
        roe: "",
        total: "NIL",
    },
]

items.forEach(item => {
    item.descriptionLines = item.description.split(/(?=-)/)
})

function PreviousInvoice({ selected }) {
    const typographyItems = [
        { name: "BE FORWARD CO.LTD.", variant: "h4", textAlign: "center", padding: 0, },
        { name: "BFD Dodoma agent", variant: "subtitle2", textAlign: "center", padding: 0, },
        { name: "TEL: +255 652737879 / 652909405", variant: "subtitle2", textAlign: "center", padding: 0, },
        { name: "EMAIL: beforwarddodoma2018@gmail.com", variant: "subtitle2", textAlign: "center", padding: 0, },
        { name: "WEBSITE: www.beforward.jp", variant: "subtitle2", textAlign: "center", padding: 0, },
        { name: `TIN: ${selected[0]?.organization_tin}`, variant: "subtitle2", textAlign: "left", padding: 4, },
        { name: "PROFORMA INVOICE", variant: "h6", textAlign: "center", padding: -4, },
        { name: `DATE: ${dayjs().format("DD/MM/YYYY")}`, variant: "body2", textAlign: "right", padding: 0, },
        { name: `INVOICE NUMBER: ${selected[0]?.id}`, variant: "body2", textAlign: "right", padding: 0, },
        { name: `CUSTOMER NAME: ${selected[0]?.client_name}`, variant: "subtitle2", textAlign: "left", padding: 0, },
        { name: `TEL: ${selected[0]?.phone_number}`, variant: "subtitle2", textAlign: "left", padding: 0, },
        { name: `EMAIL: ${selected[0]?.email}`, variant: "subtitle2", textAlign: "left", padding: 0, },
    ]

    return (
        <>
            {typographyItems.map((item, index) => (
                <Typography
                    key={index}
                    textAlign={item.textAlign}
                    variant={item.variant}
                    sx={{ mt: item.padding }}
                >
                    {item.name}
                </Typography>
            ))}
            <TableContainer sx={{ my: 3, }}>
                <Table>
                    <TableHead
                        sx={{
                            backgroundColor: 'transparent'
                        }}
                    >
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 1 } }}
                        >
                            <TableCell align="left">
                                <Typography
                                    variant='inherit'
                                >
                                    REF.NO.
                                </Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography
                                    variant='inherit'
                                >
                                    VEHICLE DESCRIPTION
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 1 } }}
                        >
                            <TableCell align="left">
                                <Typography
                                    variant='subtitle2'
                                >
                                    {selected[0]?.car_model}
                                </Typography>
                            </TableCell>
                            <TableCell align="left">
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography
                                            variant='subtitle2'
                                        >
                                            CHASSIS NO: {selected[0]?.chassis_no}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography
                                            variant='subtitle2'
                                        >
                                            COLOR: {selected[0]?.color}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography
                                            variant='subtitle2'
                                        >
                                            YEAR: {selected[0]?.year}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography
                                            variant='subtitle2'
                                        >
                                            MILEAGE: {selected[0]?.transmission}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography
                                            variant='subtitle2'
                                        >
                                            ENGINE: {selected[0]?.engine_size}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography
                                            variant='subtitle2'
                                        >
                                            FUEL: {selected[0]?.fuel}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography
                variant='subtitle2'
            >
                BANK ACCOUNTS:BANK NAME - BFD AGENT LIMITED
            </Typography>
            <Typography
                variant='subtitle2'
            >
                ACCOUNTS NUMBER - NMB Tshs.53110008421, CRDB Tshs.015C674068700 Usd.025C674068700
            </Typography>
            <TableContainer sx={{ my: 3 }}>
                <Table>
                    <TableHead sx={{ backgroundColor: 'transparent' }}>
                        <TableRow>
                            <TableCell align="left" rowSpan={2} sx={{ border: 1 }}>
                                <Typography variant='inherit'>S/NO.</Typography>
                            </TableCell>
                            <TableCell align="center" rowSpan={2} sx={{ border: 1 }}>
                                <Typography variant='inherit'>DESCRIPTION</Typography>
                            </TableCell>
                            <TableCell align="center" rowSpan={2} sx={{ border: 1 }}>
                                <Typography variant='body1'>QYY</Typography>
                            </TableCell>
                            <TableCell align="center" colSpan={3} sx={{ border: 1 }}>
                                <Typography variant='inherit'>UNITY PRICE</Typography>
                            </TableCell>
                            <TableCell align="center" sx={{ border: 1 }}>
                                <Typography variant='inherit'>TOTAL</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left" sx={{ border: 1 }}>
                                <Typography variant='inherit'>USD</Typography>
                            </TableCell>
                            <TableCell align="left" sx={{ border: 1 }}>
                                <Typography variant='inherit'>TZS</Typography>
                            </TableCell>
                            <TableCell align="left" sx={{ border: 1 }}>
                                <Typography variant='inherit'>RoE</Typography>
                            </TableCell>
                            <TableCell align="left" sx={{ border: 1 }}>
                                <Typography variant='inherit'>TZS</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((item, index) => (
                            <TableRow
                                key={index}
                            >
                                <TableCell align="left" sx={{ border: 1 }}>
                                    <Typography variant='h6'>{item.id}</Typography>
                                </TableCell>
                                <TableCell align="left" sx={{ border: 1 }}>
                                    {item.descriptionLines.map((line, index) => (
                                        <Typography key={index} variant="caption">{line}<br /></Typography>
                                    ))}
                                </TableCell>
                                <TableCell align="left" sx={{ border: 1 }}>
                                    <Typography variant='inherit'>{item.quantity}</Typography>
                                </TableCell>
                                <TableCell align="left" sx={{ border: 1 }}>
                                    <Typography variant='inherit'>{item.usd}</Typography>
                                </TableCell>
                                <TableCell align="left" sx={{ border: 1 }}>
                                    <Typography variant='inherit'>{item.tzs}</Typography>
                                </TableCell>
                                <TableCell align="left" sx={{ border: 1 }}>
                                    <Typography variant='inherit'>{item.roe}</Typography>
                                </TableCell>
                                <TableCell align="left" sx={{ border: 1 }}>
                                    <Typography variant='inherit'>{item.total}</Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell align="left" colSpan={3} sx={{ border: 0 }}>
                            </TableCell>
                            <TableCell align="right" colSpan={4} sx={{ border: 1 }}>
                                <Typography variant='h6'>TOTAL 23,846,214/-</Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography variant='h6' textAlign="center">THANK YOU FOR YOUR BUSINESS!</Typography>
        </>
    )
}

export default PreviousInvoice