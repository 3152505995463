import * as React from 'react'
import PropTypes from 'prop-types'
import { Box, Tab, Tabs } from '@mui/material'
import { DescriptionOutlined, ReceiptOutlined, } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import InvoicesPaid from './InvoicesPaid'
import InvoicesNotPaid from './InvoicesNotPaid'
import Invoice from './Invoice'
import Receipt from './Receipt'
import PendingInvoices from './PendingInvoices'

function TabPanel({
    children,
    value,
    index,
}) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

export default function BasicTabs({
    rows,
    setCurrentTab,
    action,
    selected,
    from,
    setSelected,
    handleRefresh,
}) {
    const { t } = useTranslation()

    const [value, setValue] = React.useState(0)

    const handleChange = (event, newValue) => {
        setCurrentTab(newValue)
        setValue(newValue)
    }

    return (
        <Box
            sx={{ width: '100%' }}
        >
            <Box
                id="tabs"
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    mb: 3,
                }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth"
                    aria-label="basic tabs example"

                >
                    <Tab
                        icon={<DescriptionOutlined />}
                        label={action === "create" ?
                            t('invoices') :
                            from === "invoices" ?
                                t('invoices_paid') :
                                t('bookings_paid')
                        }
                        {...a11yProps(0)}
                    />
                    <Tab
                        icon={<ReceiptOutlined />}
                        label={action === "create" ?
                            t('receipt') :
                            from === "invoices" ?
                                t('invoices_not_paid') :
                                t('bookings_not_paid')
                        }
                        {...a11yProps(1)}
                    />
                    {from === "invoices" &&
                        <Tab
                            icon={<ReceiptOutlined />}
                            label={t('pending_invoices')}
                            {...a11yProps(1)}
                        />
                    }
                </Tabs>
            </Box>
            <TabPanel
                value={value}
                index={0}
            >
                {action === "create" &&
                    <Invoice
                        selected={selected}
                        setSelected={setSelected}
                        handleRefresh={handleRefresh}
                    />
                }
                {action === "view" &&
                    <InvoicesPaid
                        from={from}
                    />
                }
            </TabPanel>
            <TabPanel
                value={value}
                index={1}
            >
                {action === "create" &&
                    <Receipt
                        rows={rows}
                        selected={selected}
                    />
                }
                {action === "view" &&
                    <InvoicesNotPaid
                        from={from}
                    />
                }
            </TabPanel>
            <TabPanel
                value={value}
                index={2}
            >
                {from === "invoices" &&
                    <PendingInvoices
                        from={from}
                    />
                }
            </TabPanel>
        </Box>
    )
}