import React from 'react'
import { Box, Divider, Drawer, useMediaQuery } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { DashBoardIcon } from '../assets/icons/dashboard'
import { CarRentalOutlined, DirectionsCarOutlined, GroupOutlined, PersonOutlined, ReceiptOutlined, ReportOutlined } from '@mui/icons-material'
import uuid from 'react-uuid'
import SideBarItem from './SideBarItem'
import { useTranslation } from 'react-i18next'

function SideBar({ open, onClose }) {
    const { t } = useTranslation()

    const items = [
        {
            href: '/',
            icon: (<DashBoardIcon fontSize="small" />),
            title: t('dashboard')
        },
        {
            href: '/cars',
            icon: (<DirectionsCarOutlined fontSize="small" />),
            title: t('cars_registration')
        },
        {
            href: '/drivers',
            icon: (<PersonOutlined fontSize="small" />),
            title: t('drivers')
        },
        {
            href: '/orders',
            icon: (<CarRentalOutlined fontSize="small" />),
            title: t('orders')
        },
        {
            href: '/invoices',
            icon: (<ReceiptOutlined fontSize="small" />),
            title: t('invoices')
        },
        // {
        //     href: '/bookings',
        //     icon: (<ReceiptOutlined fontSize="small" />),
        //     title: t('bookings')
        // },
        {
            href: '/users',
            icon: (<GroupOutlined fontSize="small" />),
            title: t('users')
        },
        {
            href: '/reports',
            icon: (<ReportOutlined fontSize="small" />),
            title: t('reports')
        },
    ]

    const router = useLocation

    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
        defaultMatches: true,
        noSsr: false
    })

    React.useEffect(
        () => {
            if (!router.isReady) {
                return
            }

            if (open) {
                onClose?.()
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [router.asPath]
    )

    const content = (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%'
                }}
            >
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                    }}
                >
                    <Box
                        component='img'
                        sx={{
                            width: 0.48,
                            mx: 'auto',
                        }}
                        alt="Logo"
                        src={require("../assets/images/logo.png")}
                    />
                </Box>
                <Divider
                    sx={{
                        borderColor: 'primary.main',
                        mb: 3
                    }}
                />
                <Box sx={{ flexGrow: 1, }}>
                    {items.map((item) => (
                        <SideBarItem
                            key={uuid()}
                            icon={item.icon}
                            href={item.href}
                            title={item.title}
                        />
                    ))}
                </Box>
            </Box>
        </>
    )

    if (lgUp) {
        return (
            <Drawer
                id='drawer'
                anchor="left"
                open
                PaperProps={{
                    sx: {
                        backgroundColor: 'background.paper',
                        color: '#FFFFFF',
                        width: 280
                    }
                }}
                variant="permanent"
            >
                {content}
            </Drawer>
        )
    }

    return (
        <Drawer
            id='drawer'
            anchor="left"
            onClose={onClose}
            open={open}
            PaperProps={{
                sx: {
                    backgroundColor: 'background.paper',
                    color: '#FFFFFF',
                    width: 280
                }
            }}
            sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
            variant="temporary"
        >
            {content}
        </Drawer>
    )
}

export default SideBar