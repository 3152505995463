import axios from "axios"

export const postAPIRequest = async (url, body, setData, mappingFunction, setIsLoading) => {
    setData([])
    try {
        const storedUserMap = localStorage.getItem('carRentingWebUser')
        const mapStoredUser = JSON.parse(storedUserMap)
        setIsLoading(true)
        const response = await axios.post(
            url,
            body,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'car-renting-sign-auth': `${process.env.REACT_APP_KEY}`,
                    'car-renting-token-auth': mapStoredUser.token,
                },
            }
        )
        // console.log('Data:', response)
        if (response.data.error === false) {
            // const mappedData = response.data.data.map(apiResponse => mappingFunction(apiResponse))
            setData(response.data.data)
        }
        setIsLoading(false)
    } catch (error) {
        setIsLoading(false)
        console.error('Error:', error)
    }
}