import { NavLink, useLocation } from 'react-router-dom'
import { Box, Button, ListItem } from '@mui/material'

function SideBarItem({ href, icon, title, }) {
    const router = useLocation()
    const { pathname } = router
    const active = href ? (pathname === href) : false

    return (
        <NavLink
            end to={href}
            replace={true}
        >
            <ListItem
                disableGutters
                sx={{
                    display: 'flex',
                    mb: 0.5,
                    py: 0,
                    px: 2,
                }}
            >
                <Button
                    startIcon={icon}
                    disableRipple
                    sx={{
                        backgroundColor: active && 'rgba(254, 0, 2, 0.5)',
                        borderRadius: 1,
                        color: 'neutral.900',
                        fontWeight: active && 'fontWeightBold',
                        justifyContent: 'flex-start',
                        px: 3,
                        textAlign: 'left',
                        textTransform: 'none',
                        width: '100%',
                        '& .MuiButton-startIcon': {
                            color: 'neutral.900'
                        },
                        '&:hover': {
                            backgroundColor: 'rgba(254, 0, 2, 0.5)'
                        }
                    }}
                >
                    <Box sx={{ flexGrow: 1 }}>
                        {title}
                    </Box>
                </Button>
            </ListItem>
        </NavLink>
    )
}

export default SideBarItem