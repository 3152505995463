import React from 'react'
import { TabPanel } from '../components'

function Invoices() {
    const [currentTab, setCurrentTab] = React.useState(0)

    return (
        <>
            {currentTab !== 3 &&
                <TabPanel
                    setCurrentTab={setCurrentTab}
                    action="view"
                    from="invoices"
                />
            }
        </>
    )
}

export default Invoices