import * as React from 'react'
import { DataGrid, ViewDialog, } from '../components'
import { useTranslation } from 'react-i18next'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { carRentingHeader } from '../utils/tableHeaders'
import { getRentingsUnpaidUrl } from '../utils/urls'
import { postAPIRequest } from '../services/postAPIRequest'
import rentingsModel from '../models/rentings'
import dayjs from 'dayjs'
import { RemoveRedEyeOutlined } from '@mui/icons-material'

pdfMake.vfs = pdfFonts.pdfMake.vfs

function InvoicesNotPaid({ from }) {
    const { t } = useTranslation()
    const [rows, setRows] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(true)
    const [action, setAction] = React.useState("")
    const [selected, setSelected] = React.useState([])
    const [openViewDialog, setOpenViewDialog] = React.useState(false)
    const [searchTerm, setSearchTerm] = React.useState("")
    const [body, setBody] = React.useState({
        "from": from === "invoices" ? dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss.SSS') :
            dayjs().add(1, 'day').startOf('day').add(5, "minute").format('YYYY-MM-DD HH:mm:ss.SSS'),
        "to": from === "invoices" ? dayjs().format('YYYY-MM-DD HH:mm:ss.SSS') :
            dayjs().add(1, 'year').format('YYYY-MM-DD HH:mm:ss.SSS'),
    })

    React.useEffect(() => {
        postAPIRequest(getRentingsUnpaidUrl, body, setRows, rentingsModel, setIsLoading)
    }, [body])

    const handleSearch = event => {
        setSearchTerm(event.target.value)
    }

    const handleClickOpenViewDialog = () => {
        setOpenViewDialog(true)
    }

    const handleCloseViewDialog = () => {
        setOpenViewDialog(false)
    }

    const handleRefresh = () => {
        postAPIRequest(
            getRentingsUnpaidUrl,
            body,
            setRows,
            rentingsModel,
            setIsLoading
        )
    }

    const carRentingPopoverItems = [
        {
            id: 'viewInvoice',
            label: 'view',
            icon: <RemoveRedEyeOutlined fontSize="small" />,
            onClick: () => {
                setAction("invoice")
                handleClickOpenViewDialog()
            },
        },
    ]

    return (
        <>
            <ViewDialog
                openViewDialog={openViewDialog}
                handleCloseViewDialog={handleCloseViewDialog}
                rows={rows}
                action={action}
                selected={selected}
                setSelected={setSelected}
                handleRefresh={handleRefresh}
            />
            <DataGrid
                t={t}
                rows={rows}
                isLoading={isLoading}
                popoverItems={carRentingPopoverItems}
                headCells={carRentingHeader}
                setSelected={setSelected}
                label={from === "invoices" ? "invoices_not_paid" : "bookings_not_paid"}
                searchTerm={searchTerm}
                handleSearch={handleSearch}
                from="renting"
                setBody={setBody}
            />
        </>
    )
}

export default InvoicesNotPaid