import * as React from 'react'
import { DataGrid, ProfileDialog, ViewDialog, } from '../components'
import { useTranslation } from 'react-i18next'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { userHeader } from '../utils/tableHeaders'
import { changeUserActiveStatusUrl, getAllUserUrl, registerUserUrl } from '../utils/urls'
import { Button, Grid } from '@mui/material'
import { Box } from '@mui/system'
import { PersonAddOutlined, RemoveRedEyeOutlined } from '@mui/icons-material'
import { postAPIRequest } from '../services/postAPIRequest'
import usersModel from '../models/users'
import dayjs from 'dayjs'
import { registerUserFields } from '../utils/formFields'
import { AuthContext } from '../contexts/AuthContext'
import axios from 'axios'

pdfMake.vfs = pdfFonts.pdfMake.vfs

const values = [
    {
        full_name: "",
        email: "",
        phone_no: "",
        password: "",
    },
]

function Users() {
    const { t } = useTranslation()
    const { currentUser, } = React.useContext(AuthContext)
    const [rows, setRows] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(true)
    const [action, setAction] = React.useState("")
    const [selected, setSelected] = React.useState([])
    const [openProfileDialog, setOpenProfileDialog] = React.useState(false)
    const [openViewDialog, setOpenViewDialog] = React.useState(false)
    const [searchTerm, setSearchTerm] = React.useState("")
    const [isSubmitting, setIsSubmitting] = React.useState(false)
    const [body, setBody] = React.useState({
        "from": dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss.SSS'),
        "to": dayjs().add(5, "minute").format('YYYY-MM-DD HH:mm:ss.SSS'),
    })

    React.useEffect(() => {
        postAPIRequest(getAllUserUrl, body, setRows, usersModel, setIsLoading)
    }, [body])

    const handleSearch = event => {
        setSearchTerm(event.target.value)
    }

    const handleClickOpenProfileDialog = () => {
        setOpenProfileDialog(true)
    }

    const handleCloseProfileDialog = () => {
        setOpenProfileDialog(false)
    }

    const handleClickOpenViewDialog = () => {
        setOpenViewDialog(true)
    }

    const handleCloseViewDialog = () => {
        setOpenViewDialog(false)
    }

    const handleRefresh = () => {
        postAPIRequest(getAllUserUrl, body, setRows, usersModel, setIsLoading)
    }

    const handleSwitchChange = async (row, index) => {
        setIsSubmitting(true)
        try {
            const response = await axios.post(
                changeUserActiveStatusUrl,
                { "user_id": row.id, "adimin_id": currentUser?.id },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'car-renting-sign-auth': `${process.env.REACT_APP_KEY}`,
                        'car-renting-token-auth': currentUser?.token,
                    },
                }
            )
            if (response.data.error === false) {
                handleRefresh()
                setIsSubmitting(false)
            }
        } catch (error) {
            setIsSubmitting(false)
        }
    }

    const userPopoverItems = [
        {
            id: 'viewUser',
            label: 'view',
            icon: <RemoveRedEyeOutlined fontSize="small" />,
            onClick: () => {
                setAction("user")
                handleClickOpenViewDialog()
            },
        },
    ]

    return (
        <>
            <Grid container>
                <Grid item xs={6}>
                    <Box
                        sx={{
                            display: 'flex',
                            ml: 3,
                            pt: 4,
                        }}
                    >
                        <Button
                            startIcon={<PersonAddOutlined />}
                            variant="contained"
                            size="small"
                            sx={{
                                '&:hover': {
                                    backgroundColor: "primary.main",
                                },
                            }}
                            onClick={() => {
                                handleClickOpenProfileDialog()
                            }}
                        >
                            {t('register_user')}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <ProfileDialog
                openProfileDialog={openProfileDialog}
                handleCloseProfileDialog={handleCloseProfileDialog}
                from="register"
                handleRefresh={handleRefresh}
                values={values}
                fields={registerUserFields}
                url={registerUserUrl}
                action="create"
                title="register_user"
                submitButtonText="create"
            />
            <ViewDialog
                openViewDialog={openViewDialog}
                handleCloseViewDialog={handleCloseViewDialog}
                action={action}
                selected={selected}
            />
            <DataGrid
                t={t}
                rows={rows}
                isLoading={isLoading}
                popoverItems={userPopoverItems}
                headCells={userHeader}
                setSelected={setSelected}
                label="registered_users"
                searchTerm={searchTerm}
                handleSearch={handleSearch}
                from="users"
                setBody={setBody}
                handleSwitchChange={handleSwitchChange}
                isSubmitting={isSubmitting}
            />
        </>
    )
}

export default Users