import React from 'react'
import { Box, Card, CardContent, CardHeader, CircularProgress, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { addOrRemoveTaskForSpecificUserUrl, getTaskForSpecificUserUrl } from '../utils/urls'
import { postAPIRequest } from '../services/postAPIRequest'
import tasksModel from '../models/tasks'
import { AuthContext } from '../contexts/AuthContext'
import axios from 'axios'

function User({ selected }) {
    const { t } = useTranslation()
    const { currentUser, } = React.useContext(AuthContext)
    const [tasks, setTasks] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(true)
    const [isSubmitting, setIsSubmitting] = React.useState(false)

    React.useEffect(() => {
        postAPIRequest(getTaskForSpecificUserUrl, { "user_id": selected[0].id }, setTasks, tasksModel, setIsLoading)
    }, [selected])

    const handleRefresh = () => {
        postAPIRequest(getTaskForSpecificUserUrl, { "user_id": selected[0].id }, setTasks, tasksModel, setIsLoading)
    }

    const handleAssignTask = async (task, index) => {
        setIsSubmitting(true)
        try {
            const response = await axios.post(
                addOrRemoveTaskForSpecificUserUrl,
                { "user_id": selected[0].id, "adimin_id": currentUser?.id, "task_id": task.id },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'car-renting-sign-auth': `${process.env.REACT_APP_KEY}`,
                        'car-renting-token-auth': currentUser?.token,
                    },
                }
            )
            if (response.data.error === false) {
                handleRefresh()
                setIsSubmitting(false)
            }
        } catch (error) {
            setIsSubmitting(false)
        }
    }

    return (
        <Card
            variant='outlined'
        >
            <CardHeader
                subheader={selected[0]?.role}
                title={selected[0]?.full_name}
            />
            <CardContent sx={{ pt: 0 }}>
                <TableContainer
                    sx={{
                        width: "100%",
                    }}
                >
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("task")}</TableCell>
                                <TableCell align="right">{t("status")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tasks.map((task, index) => (
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {task.parameter}
                                    </TableCell>
                                    <TableCell align="right">
                                        <Switch
                                            checked={task.assigned}
                                            onChange={() => {
                                                handleAssignTask(task, index)
                                            }}
                                            disabled={isSubmitting}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {isLoading === true &&
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <CircularProgress
                            sx={{
                                mx: 'auto',
                                my: 3,
                            }}
                        />
                    </Box>
                }
            </CardContent>
        </Card>
    )
}

export default User