import { Avatar, Box, Card, CardContent, Grid, Typography } from '@mui/material'
import theme from '../theme'

function DashBoardCard({ title, subtitle, body, icon, }) {
    return (
        <Card
            elevation={10}
            sx={{
                '&:hover': {
                    transition: "transform 0.15s ease-in-out",
                    transform: "scale3d(1.05, 1.05, 1)",
                },
            }}
        >
            <CardContent className="bg-slate-100 hover:bg-slate-200" >
                <Grid
                    container
                    spacing={3}
                    sx={{ justifyContent: 'space-between' }}
                >
                    <Grid item xs={6}>
                        <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="overline"
                            noWrap={true}
                            sx={{
                                width: 1,
                                display: "block",
                            }}
                        >
                            {title}
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="h4"
                        >
                            {subtitle}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row-reverse',
                        }}
                    >
                        <Avatar
                            sx={{
                                backgroundColor: 'white',
                                height: 56,
                                width: 56,
                                border: `2px solid ${theme.palette.primary.main}`
                            }}
                        >
                            {icon}
                        </Avatar>
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        pt: 2
                    }}
                >
                    <Typography
                        color="primary"
                        variant="caption"
                    >
                        {body}
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    )
}

export default DashBoardCard