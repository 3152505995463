class Cars {
    constructor({
        id,
        car_model,
        chassis_no,
        car_type,
        price,
        year,
        color,
        plate_number,
        fuel,
        transmission,
        engine_size,
        wheel_drive,
        image,
        rented,
        vehicle_descriptions,
        created_at,
        updated_at,
    }) {
        this.id = id
        this.car_model = car_model
        this.chassis_no = chassis_no
        this.car_type = car_type
        this.price = price
        this.year = year
        this.color = color
        this.plate_number = plate_number
        this.fuel = fuel
        this.transmission = transmission
        this.engine_size = engine_size
        this.wheel_drive = wheel_drive
        this.image = image
        this.rented = rented
        this.vehicle_descriptions = vehicle_descriptions
        this.created_at = created_at
        this.updated_at = updated_at
    }
}

function carsModel(apiResponse) {
    const {
        id,
        car_model,
        chassis_no,
        car_type,
        price,
        year,
        color,
        plate_number,
        fuel,
        transmission,
        engine_size,
        wheel_drive,
        image,
        rented,
        vehicle_descriptions,
        created_at,
        updated_at,
    } = apiResponse
    return new Cars({
        id,
        car_model,
        chassis_no,
        car_type,
        price,
        year,
        color,
        plate_number,
        fuel,
        transmission,
        engine_size,
        wheel_drive,
        image,
        rented,
        vehicle_descriptions,
        created_at,
        updated_at,
    })
}

export default carsModel