import { CloseOutlined } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, Slide, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from '@mui/material'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import axios from 'axios'
import dayjs from 'dayjs'
import { Form, Formik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import uuid from 'react-uuid'
import * as Yup from "yup"
import { AuthContext } from '../contexts/AuthContext'
import { genders, roles } from '../utils/constants'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

function ProfileDialog({
    openProfileDialog,
    handleCloseProfileDialog,
    from,
    handleRefresh,
    values,
    fields,
    url,
    action,
    title,
    submitButtonText,
    selected,
    setSeverity,
    setSeverityMessage,
    handleClickAlert,
}) {

    const { t } = useTranslation()
    const { currentUser, } = React.useContext(AuthContext)
    const [gender, setGender] = React.useState("MALE")
    const [role, setRole] = React.useState("SECRETARY")
    const [error, setError] = React.useState("")

    const handleChangeGender = (event) => {
        setGender(event.target.value)
    }

    const handleChangeRole = (event) => {
        setRole(event.target.value)
    }

    const schema = Yup.object().shape(
        fields.reduce((obj, field) => {
            if (field.type === 'email') {
                obj[field.name] = Yup.string()
                    .email(`${t(field.label)} ${t("must_be_valid_email")}`)
                    .required(`${t(field.label)} ${t("is_required")}`)
            } else {
                obj[field.name] = Yup.string().required(`${t(field.label)} ${t("is_required")}`)
            }
            return obj;
        }, {})
    )

    const [dateOfBirth, setDateOfBirth] = React.useState(dayjs())

    const handleArrivalTime = (newValue) => {
        setDateOfBirth(newValue)
    }

    return (
        <Dialog
            open={openProfileDialog}
            TransitionComponent={Transition}
            onClose={handleCloseProfileDialog}
            fullWidth
            maxWidth="sm"
        >
            <Formik
                enableReinitialize
                initialValues={{ ...values[0] }}
                validationSchema={schema}
                onSubmit={async (values, { setSubmitting, resetForm, setErrors }) => {
                    const requestBody = from === "profile" ?
                        { ...values, "user_id": currentUser?.id } :
                        from === "driver" && action === "edit" ?
                            { ...values, "id": selected.length === 1 && selected[0]?.id, "user_id": currentUser?.id } :
                            from === "driver" ?
                                { ...values, "date_of_birth": dateOfBirth, "gender": gender, "user_id": currentUser?.id } :
                                { ...values, "role": role, "gender": gender, "user_id": currentUser?.id }
                    try {
                        const response = await axios.post(
                            url,
                            requestBody,
                            {
                                headers: {
                                    'Content-Type': 'application/json',
                                    'car-renting-sign-auth': `${process.env.REACT_APP_KEY}`,
                                    'car-renting-token-auth': currentUser?.token,
                                },
                            }
                        )
                        if (response.data.error === false) {
                            if (from === "profile" || action === "edit") {
                                handleCloseProfileDialog()
                                setSeverityMessage(response.data.message)
                                setSeverity("success")
                                handleClickAlert()
                            } else {
                                handleRefresh()
                            }
                            resetForm()
                            setSubmitting(false)
                        }
                    } catch (error) {
                        setErrors(error.response.data)
                        error.response.data.message && setError(error.response.data.message[0])
                        setSubmitting(false)
                    }
                }}
            >
                {({ isSubmitting, values, touched, errors, handleChange, handleBlur }) => (
                    <Form
                        noValidate
                        autoComplete="off"
                    >
                        <DialogTitle>
                            {action !== "view" && t(title)}
                            {action === "view" &&
                                <IconButton
                                    aria-label="close"
                                    onClick={handleCloseProfileDialog}
                                >
                                    <CloseOutlined />
                                </IconButton>
                            }
                        </DialogTitle>
                        {action !== "view" &&
                            <DialogContent>
                                {fields.map((field) => (
                                    <TextField
                                        color='secondary'
                                        name={field.name}
                                        type={field.type}
                                        label={t(field.label)}
                                        margin="normal"
                                        fullWidth
                                        key={field.name}
                                        value={values[field.name]}
                                        error={Boolean(errors[field.name] && touched[field.name])}
                                        helperText={touched[field.name] && errors[field.name]}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    />
                                ))}
                                {from !== "profile" &&
                                    <>
                                        {action === "create" &&
                                            <TextField
                                                id="role"
                                                color='secondary'
                                                select
                                                margin='normal'
                                                label={t("gender")}
                                                value={gender}
                                                onChange={handleChangeGender}
                                                fullWidth
                                            >
                                                {genders.map((gender) => (
                                                    <MenuItem
                                                        key={uuid()}
                                                        value={gender.value}
                                                    >
                                                        {gender.value}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        }
                                        {from === "register" &&
                                            <TextField
                                                id="role"
                                                color='secondary'
                                                select
                                                margin='normal'
                                                label={"Role"}
                                                value={role}
                                                onChange={handleChangeRole}
                                                fullWidth
                                            >
                                                {roles.map((role) => (
                                                    <MenuItem
                                                        key={uuid()}
                                                        value={role.value}
                                                    >
                                                        {role.value}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        }
                                        {from === "driver" && action === "create" &&
                                            <LocalizationProvider
                                                dateAdapter={AdapterDayjs}
                                            >
                                                <DateTimePicker
                                                    label={t("date_of_birth")}
                                                    value={dateOfBirth}
                                                    sx={{ width: "100%", }}
                                                    onChange={handleArrivalTime}
                                                    slotProps={{
                                                        textField: {
                                                            color: 'secondary',
                                                            margin: "normal",
                                                        }
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        }
                                    </>
                                }
                                {error !== "" &&
                                    <Typography
                                        sx={{
                                            color: "red",
                                        }}
                                    >
                                        {error}
                                    </Typography>
                                }
                            </DialogContent>
                        }
                        {action === "view" &&
                            <DialogContent>
                                <TableContainer>
                                    <Table aria-label="simple table">
                                        <TableBody>
                                            {fields.map((field) => (
                                                <TableRow
                                                    key={field.label}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {t(field.label)}
                                                    </TableCell>
                                                    <TableCell align="right">{values[field.name]}</TableCell>
                                                </TableRow>
                                            ))}
                                            <TableRow
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {t("gender")}
                                                </TableCell>
                                                <TableCell align="right">{gender}</TableCell>
                                            </TableRow>
                                            <TableRow
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {t("date_of_birth")}
                                                </TableCell>
                                                <TableCell align="right">{selected[0]?.date_of_birth}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </DialogContent>
                        }
                        {action !== "view" &&
                            <DialogActions>
                                <Button
                                    onClick={handleCloseProfileDialog}
                                >
                                    {t('cancel')}
                                </Button>
                                <Button
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    {t(submitButtonText)}
                                </Button>
                            </DialogActions>
                        }
                    </Form>
                )}
            </Formik>
        </Dialog>
    )
}

export default ProfileDialog