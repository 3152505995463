class Tasks {
    constructor({
        id,
        parameter,
        assigned,
        created_at,
    }) {
        this.id = id
        this.parameter = parameter
        this.assigned = assigned
        this.created_at = created_at
    }
}

function tasksModel(apiResponse) {
    const {
        id,
        parameter,
        assigned,
        created_at,
    } = apiResponse
    return new Tasks({
        id,
        parameter,
        assigned,
        created_at,
    })
}

export default tasksModel