import * as React from 'react'
import { DataGrid, ViewDialog, } from '../components'
import { useTranslation } from 'react-i18next'
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { carRentingHeader } from '../utils/tableHeaders'
import { getRentingsUrl } from '../utils/urls'
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, useMediaQuery } from '@mui/material'
import { Box } from '@mui/system'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { postAPIRequest } from '../services/postAPIRequest'
import rentingsModel from '../models/rentings'
import { RemoveRedEyeOutlined } from '@mui/icons-material';

pdfMake.vfs = pdfFonts.pdfMake.vfs

function Reports() {
  const { t } = useTranslation()
  const [rows, setRows] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)
  const [action, setAction] = React.useState("")
  const [openViewDialog, setOpenViewDialog] = React.useState(false)
  const [selected, setSelected] = React.useState([])
  const [searchTerm, setSearchTerm] = React.useState("")
  const [body, setBody] = React.useState({
    "from": dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss.SSS'),
    "to": dayjs().add(5, "minute").format('YYYY-MM-DD HH:mm:ss.SSS'),
  })

  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
    defaultMatches: true,
    noSsr: false
  })

  React.useEffect(() => {
    postAPIRequest(getRentingsUrl, body, setRows, rentingsModel, setIsLoading)
  }, [body])

  const summaries = [
    {
      name: "paid",
      count: rows.filter(item =>
        Object.values(item).some(value =>
          value
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        ) && item.status === true
      ).length,
      car_price: rows.filter(item =>
        Object.values(item).some(value =>
          value
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        ) && item.status === true
      ).reduce((acc, item) => {
        if (item.car_price) {
          return acc + item.car_price
        }
        return acc
      }, 0),
      driver_price: rows.filter(item =>
        Object.values(item).some(value =>
          value
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        ) && item.status === true
      ).reduce((acc, item) => {
        if (item.driver_price) {
          return acc + item.driver_price
        }
        return acc
      }, 0),
    },
    {
      name: "not_paid",
      count: rows.filter(item =>
        Object.values(item).some(value =>
          value
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        ) && item.status === false
      ).length,
      car_price: rows.filter(item =>
        Object.values(item).some(value =>
          value
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        ) && item.status === false
      ).reduce((acc, item) => {
        if (item.car_price) {
          return acc + item.car_price
        }
        return acc
      }, 0),
      driver_price: rows.filter(item =>
        Object.values(item).some(value =>
          value
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        ) && item.status === false
      ).reduce((acc, item) => {
        if (item.driver_price) {
          return acc + item.driver_price
        }
        return acc
      }, 0),
    },
    {
      name: "total",
      count: rows.filter(item =>
        Object.values(item).some(value =>
          value
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        )
      ).length,
      car_price: rows.filter(item =>
        Object.values(item).some(value =>
          value
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        )
      ).reduce((acc, item) => {
        if (item.car_price) {
          return acc + item.car_price
        }
        return acc
      }, 0),
      driver_price: rows.filter(item =>
        Object.values(item).some(value =>
          value
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        )
      ).reduce((acc, item) => {
        if (item.driver_price) {
          return acc + item.driver_price
        }
        return acc
      }, 0),
    },
  ]

  const handleSearch = event => {
    setSearchTerm(event.target.value)
  }

  const [fromDate, setFromDate] = React.useState(dayjs().startOf('day'))

  const handleArrivalTime = (newValue) => {
    setFromDate(newValue)
    setBody({ ...body, "from": newValue.format('YYYY-MM-DD HH:mm:ss.SSS'), })
  }

  const [toDate, setToDate] = React.useState(dayjs())

  const handleDepatcherTime = (newValue) => {
    setToDate(newValue)
    setBody({ ...body, "to": newValue.format('YYYY-MM-DD HH:mm:ss.SSS') })
  }

  const handleClickOpenViewDialog = () => {
    setOpenViewDialog(true)
  }

  const handleCloseViewDialog = () => {
    setOpenViewDialog(false)
  }

  const handleRefresh = () => {
    postAPIRequest(
      getRentingsUrl,
      body,
      setRows,
      rentingsModel,
      setIsLoading
    )
  }

  const carRentingPopoverItems = [
    {
      id: 'viewInvoice',
      label: 'view',
      icon: <RemoveRedEyeOutlined fontSize="small" />,
      onClick: () => {
        setAction("invoice")
        handleClickOpenViewDialog()
      },
    },
  ]

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              ml: 3,
              pt: 4,
            }}
          >
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
            >
              <DateTimePicker
                label={t("from")}
                value={fromDate}
                onChange={handleArrivalTime}
                textField={
                  (params) =>
                    <TextField
                      fullWidth
                      color='secondary'
                      margin="normal"
                    />
                }
              />
              <DateTimePicker
                sx={{ mx: 3 }}
                label={t("to")}
                value={toDate}
                onChange={handleDepatcherTime}
                textField={
                  (params) =>
                    <TextField
                      fullWidth
                      color='secondary'
                      margin="normal"
                    />
                }
              />
            </LocalizationProvider>
          </Box>
        </Grid>
      </Grid>
      <Typography
        variant='h6'
        sx={{
          py: 2,
          ml: 3,
        }}
      >
        {t("summary")}
      </Typography>
      <Box
        sx={{
          width: '100%',
          px: 3,
          py: 2,
        }}
      >
        <Paper sx={{ width: lgUp ? '50%' : '100%', mb: 2 }}>
          <TableContainer>
            <Table
              sx={{
                minWidth: 400,
              }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>{t("name")}</TableCell>
                  <TableCell align="right">{t("count")}</TableCell>
                  <TableCell align="right">{t("car_price")}</TableCell>
                  <TableCell align="right">{t("driver_price")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {summaries.map((summary, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {t(summary.name)}
                    </TableCell>
                    <TableCell align="right">{summary.count}</TableCell>
                    <TableCell align="right">{summary.car_price} TZS</TableCell>
                    <TableCell align="right">{summary.driver_price} TZS</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
      <ViewDialog
        openViewDialog={openViewDialog}
        handleCloseViewDialog={handleCloseViewDialog}
        rows={rows}
        action={action}
        selected={selected}
        setSelected={setSelected}
        handleRefresh={handleRefresh}
      />
      <DataGrid
        t={t}
        rows={rows}
        isLoading={isLoading}
        popoverItems={carRentingPopoverItems}
        headCells={carRentingHeader}
        setSelected={setSelected}
        label="car_renting"
        searchTerm={searchTerm}
        handleSearch={handleSearch}
        from="renting"
        setBody={setBody}
      />
    </>
  )
}

export default Reports